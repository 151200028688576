.partnersAndSponsors__logosWrap {
  display: flex;
  flex-wrap: wrap;
  margin-inline: -8px;
  margin-top: -16px;
}

.partnersAndSponsors__logosWrap > a {
  margin-inline: 8px;
  margin-top: 16px;
  background-color: #ffffff;
  padding: 6px 12px;
  border-radius: 3px;
  width: 100%;
  max-width: 114.75px;
}

.partnersAndSponsors__logo {
  width: 100%;
  height: auto;
  max-height: 68px;
  object-fit: cover;
}
