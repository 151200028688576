.sidePanel {
  height: calc(100vh - 244px);
  width: 100%;
  max-width: 565px;
  background-color: #054379;
  position: absolute;
  top: 50%;
  transform: translate(-100%, -50%);
  -webkit-transition: transform 0.25s;
  -moz-transition: transform 0.25s;
  -o-transition: transform 0.25s;
  transition: transform 0.25s;
  overflow-x: hidden;
}

.sidePanel__active {
  transform: translate(18.5px, -50%);
  overflow-y: auto;
}

.sidePanel__header {
  position: sticky;
  top: 0;
  height: 110px;
  padding: 12px 24px;
  background-color: #054379;
  border-bottom: 1px solid #10203e;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}

.sidePanel__headerLogo {
  background-color: #ffffff;
  border-radius: 3px;
  padding-inline: 12px;
}

.sidePanel__headerLogo > img {
  width: 100%;
  height: auto;
}

.sidePanel__header > img {
  height: 100%;
  width: auto;
  max-width: 250px;
  object-fit: contain;
}

.sidePanel__header > button {
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: unset;
  outline: unset;
  border: unset;
  cursor: pointer;
  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s;
}

.sidePanel__header > button > img {
  width: 16px;
  height: 16px;
}

.sidePanel__header > button:focus {
  outline: 1px solid #3291cf;
}

.sidePanel__header > button:hover,
.sidePanel__header > button:focus {
  background-color: #1a64a7;
}

.sidePanel__body {
  margin-top: 38px;
  padding-inline: 24px;
}
