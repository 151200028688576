.programs__card {
  width: 447px;
  min-height: 287px;
  overflow: hidden;
  flex-shrink: 0;
  border: 1px solid #6283de;
  border-radius: 8px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  display: flex;
  flex-direction: column;
}

.programs__card.hidden-content {
  height: 287px;
}

.programs__card:not(:last-of-type) {
  margin-inline-end: 32px;
}

.programs__cardHeader {
  border-bottom: 1px solid #6283de;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  padding: 16px 15px;
  margin-bottom: 18px;
  background: transparent linear-gradient(270deg, #176bb2 0%, #5f245b 87%) 0% 0%
    no-repeat padding-box;
}

.programs__cardHeader > h4,
.programs__information > h5,
.programs__information > a,
.programs__tag {
  color: #ffffff;
}

.programs__cardHeader > h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 17px;
  text-transform: uppercase;
}

.programs__cardBody {
  padding-inline: 15px;
  margin-bottom: auto;
  color: #ffffff !important;
}

.programs__cardBody.hidden-body {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  max-height: 144px;
}

.programs__cardFooter {
  margin-block: 18px;
  padding-inline: 15px;
  display: flex;
}

.programs__showMore {
  flex-shrink: 0;
  margin-inline-start: auto;
}

.programs__showMore,
.programs__showMore > button {
  width: 22px;
  height: 22px;
  border-radius: 100%;
}

.programs__showMore > button {
  background: unset;
  border: unset;
  outline: unset;
  cursor: pointer;
  -webkit-transition: transform 0.25s;
  -moz-transition: transform 0.25s;
  -o-transition: transform 0.25s;
  transition: transform 0.25s;
}

.programs__showMore.collapsed > button {
  transform: rotate(45deg);
}

.programs__showMore > button:hover {
  background-image: linear-gradient(0.25turn, #184e7c, #5f245b, #a1141d);
}

.programs__tagWrap {
  display: flex;
  flex-wrap: wrap;
  margin-inline: -4px;
  margin-inline-end: 15px;
}

.programs__tagWrap.hidden-tags {
  overflow: hidden;
  height: 32px;
}

.programs__tag {
  padding-inline: 12px;
  display: flex;
  align-items: center;
  height: 24px;
  background-color: #60215c;
  border-radius: 12px;
  margin-inline: 4px;
  margin-bottom: 8px;
}

.programs__tag > span {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-transform: capitalize;
}
