.parentInstitutions__logosWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-inline: -8px;
  margin-top: -16px;
}

.parentInstitutions__logosWrap > a {
  margin-inline: 8px;
  margin-top: 16px;
}

.parentInstitutions__logo {
  height: 100%;
  max-height: 68px;
  width: auto;
}
