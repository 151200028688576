.siteLogo {
  display: flex;
  align-items: flex-start;
  margin-inline: -16px;
}

.siteLogo > a {
  margin-inline: 16px;
  text-decoration: none;
}

.siteLogo > a > img {
  width: 100%;
}

.siteLogo__atlantic {
  max-width: 188px;
}

.siteLogo__empower {
  max-width: 160px;
  padding-top: 22px;
}

@media screen and (max-width: 768px) {
  .siteLogo__atlantic,
  .siteLogo__empower {
    max-width: 100px;
  }
}
