.selectMenu__listIcon {
  width: 20px;
  height: 20px;
  margin-inline-end: 12px;
}

.selectMenu__listIcon > img {
  width: inherit;
  height: inherit;
}

.selectMenu__listItem {
  display: flex;
  align-items: unset;
  padding: 7px 16px;
}

.selectMenu__listItem > .selectMenu__option {
  padding: unset;
}
