.poweredBy {
  position: absolute;
  top: 35px;
  right: 35px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .poweredBy {
    max-width: 100px;
    right: 15px;
    top: 15px;
  }

  .poweredBy > img {
    width: 100%;
  }
}
