.collaborateWithUs {
  width: 217px;
  border: unset;
  background-color: #ffffff;
  border-radius: 3px;
  height: 45px;
  color: #34436a;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  margin-top: 30px;
  margin-inline-start: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: unset;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s;
}

.collaborateWithUs:hover {
  background-color: rgba(255, 255, 255, 0.63);
}

@media screen and (max-width: 768px) {
  .collaborateWithUs {
    width: 175px;
    margin-inline-start: 14px;
    margin-top: 14px;
  }
}
