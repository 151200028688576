.sliderComponent {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  cursor: grab;
}

.sliderComponent:active {
  cursor: grabbing;
}

.sliderComponent::-webkit-scrollbar {
  height: 0;
}
