.glance {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.glance__text {
  margin-top: 18px;
  margin-bottom: 32px;
}
