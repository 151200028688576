.welcomeModal__wrap {
  width: 100vw;
  height: 100vh;
  background-color: rgba(3, 36, 64, 0.86);
  position: absolute;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcomeModal {
  width: 56.75vw;
  min-height: 72.9vh;
  max-height: calc(100vh - 48px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-block: 24px;
  background-color: #054379;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  -o-animation: slideToBottom 0.25s;
  -moz-animation: slideToBottom 0.25s;
  -webkit-animation: slideToBottom 0.25s;
  animation: slideToBottom 0.25s;
  position: relative;
}

@-webkit-keyframes slideToBottom {
  from {
    top: -150vh;
  }
  to {
    top: 0;
  }
}

@keyframes slideToBottom {
  from {
    top: -150vh;
  }
  to {
    top: 0;
  }
}

.welcomeModal__header,
.welcomeModal__body,
.welcomeModal__footer {
  padding-inline: 15.1%;
}

.welcomeModal__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin-top: auto;
  margin-inline: -12px;
}

.welcomeModal__header > img {
  width: 100%;
  padding-block: 12px;
  margin-inline: 12px;
}

.welcomeModal__atlanticLogo {
  max-width: 230px;
}

.welcomeModal__empowerMeLogo {
  max-width: 260px;
}

.welcomeModal__body {
  padding-bottom: 60px;
  padding-top: 48px;
}

.welcomeModal__body > p {
  font-size: 22px;
  line-height: 35px;
  color: #ffffff;
  text-align: center;
}

.welcomeModal__hyperlink {
  color: #3291cf;
  text-decoration: unset;
}

.welcomeModal__footer {
  text-align: center;
  margin-bottom: auto;
}

.welcomeModal__exploreButton {
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  text-transform: uppercase;
  padding-block: 17px;
  width: 100%;
  max-width: 305.44px;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 3px;
  outline: unset;
  cursor: pointer;
  -webkit-transition: background-color 0.25s, color 0.25s;
  -moz-transition: background-color 0.25s, color 0.25s;
  -o-transition: background-color 0.25s, color 0.25s;
  transition: background-color 0.25s, color 0.25s;
}

.welcomeModal__exploreButton:hover {
  background-color: #ffffff;
  color: #054379;
}

.welcomeModal__labelWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}

.welcomeModal__labelWrap > label {
  color: #ffffff;
  cursor: pointer;
}

.welcomeModal__label {
  position: relative;
  display: block;
  height: 24px;
  width: 24px;
  margin-inline-end: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.welcomeModal__label > input {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}

.welcomeModal__checkMark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 15px;
  width: 15px;
  outline: 1px solid #ffffff;
  outline-offset: 3px;
  background-color: unset;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.welcomeModal__label > input:checked ~ .welcomeModal__checkMark {
  background-color: #ffffff;
}

@media screen and (max-width: 768px) {
  .welcomeModal {
    width: calc(100vw - 48px);
  }

  .welcomeModal__body {
    padding-bottom: 60px;
    padding-top: 48px;
  }
}
