.selectMenu {
  position: relative;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  min-width: 189px;
  max-width: 189px;
  margin-inline: 6px;
}

.selectMenu__current {
  position: relative;
  cursor: pointer;
  outline: none;
}

.selectMenu__current:focus .selectMenu__icon {
  transform: translateY(-50%) rotate(180deg);
}

.selectMenu__option {
  cursor: pointer;
}

.selectMenu__icon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  -webkit-transition: transform 0.25s;
  -moz-transition: transform 0.25s;
  -o-transition: transform 0.25s;
  transition: transform 0.25s;
}

.selectMenu__value {
  display: flex;
}

.selectMenu__input {
  display: none;
}

.selectMenu__input:checked + .selectMenu__text {
  display: block;
}

.selectMenu__text {
  display: none;
  width: 100%;
  margin: 0;
  padding: 16px;
  padding-inline-end: 48px;
  background-color: rgba(000, 000, 000, 0.3);
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectMenu__current:focus .selectMenu__text {
  background-color: rgba(9, 85, 150, 0.4);
}

.selectMenu__list {
  bottom: 100%;
  position: absolute;
  width: 100%;
  max-height: 196px;
  height: 0px;
  overflow-y: auto;
  margin-bottom: 5px;
  list-style: none;
  opacity: 0;
  background-color: rgba(9, 85, 150, 0.4);
  border-radius: 3px;
  animation-name: HideList;
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: step-start;
  -webkit-transition: height 0.25s;
  -moz-transition: height 0.25s;
  -o-transition: height 0.25s;
  transition: height 0.25s;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.selectMenu__list > li:first-of-type {
  margin-top: 13px;
}

.selectMenu__list > li:last-of-type {
  margin-bottom: 13px;
}

.selectMenu__current:focus + .selectMenu__list {
  opacity: 1;
  animation-name: none;
  height: 196px;
}

.selectMenu__option {
  color: rgba(255, 255, 255, 0.31);
  display: block;
  padding: 7px 16px;
  -webkit-transition: color 0.25s;
  -moz-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.selectMenu__option:hover,
.selectMenu__option:focus {
  color: #ffffff;
}

@keyframes HideList {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}

@keyframes drawerEffect {
  from {
    height: 0px;
  }
  to {
    height: 196px;
  }
}
