@font-face {
  font-family: "proximaNova";
  src: url(./assets/fonts/proximaNova/proximaNova-regular.otf);
  font-weight: 400;
}

@font-face {
  font-family: "proximaNova";
  src: url(./assets/fonts/proximaNova/proximaNova-medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: "proximaNova";
  src: url(./assets/fonts/proximaNova/proximaNova-bold.otf);
  font-weight: 600;
}

html,
body,
* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "proximaNova", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #1a64a7;
}

/* node hover card */
.cardHover {
  overflow: hidden;
}

.cardHover p,
.cardHover h1 {
  -webkit-animation: slide-right 0.25s;
  animation: slide-right 0.25s;
}

@keyframes slide-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0px);
  }
}
