.networkGraph {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  height: 100vh;
  width: 100%;
  background-image: url(../../assets/media/background.png);
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;
}

#networkGraphContainer {
  flex: 1;
}

.DVSL-font-height {
  display: none;
}

.landing-line-separator {
  box-shadow: 0px 3px 1px rgb(91 89 91 / 70%);
  height: 3px;
  border-radius: 100%;
}

.landing-line-separator > span {
  display: none;
}

.site-logo-container {
  position: absolute;
  top: 35px;
  left: 35px;
}

.zoomChartActions {
  position: absolute;
  padding-inline-end: 35px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.filters-container {
  position: absolute;
  bottom: 35px;
  width: 100%;
}

@media screen and (max-width: 1199px) {
  .filters-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding-inline: 6px;
    left: unset;
    gap: 12px;
  }

  .filters-container > .showAllButton {
    grid-row-start: 2;
  }

  .filters-container > .resetFiltersButton,
  .filters-container > .showAllButton,
  .filters-container > .selectMenu {
    margin-inline: unset !important;
  }

  .filters-container > .resetFiltersButton,
  .filters-container > .showAllButton {
    width: unset;
  }

  .filters-container > .resetFiltersButton {
    grid-column-start: 5;
  }

  .filters-container > .selectMenu {
    max-width: unset;
  }
}

@media screen and (min-width: 1200px) {
  .filters-container {
    position: absolute;
    left: 6px;
    bottom: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: -6px;
  }
}

@media screen and (max-width: 768px) {
  .site-logo-container {
    top: 15px;
    left: 15px;
  }
}

.responseError {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.responseError > p {
  color: #ffffff;
}

.DVSL-resize-triggers {
  display: none;
}

/* Display hover card on top of buttons */
.DVSL-info-left,
.DVSL-info-right {
  z-index: 100;
}
