.statistics {
  display: grid;
  grid-template-columns: repeat(3, auto);
  row-gap: 74px;
  column-gap: 12px;
}

@media (max-width: 460px) {
  .statistics {
    grid-template-columns: auto auto;
  }
}

.statistics__wrap:nth-of-type(-n + 3) > .statistics__stat {
  color: #efb129;
}

.statistics__wrap:nth-of-type(-n + 3) .statistics__line {
  background-color: #efb129;
}

.statistics__wrap:nth-of-type(n + 4) > .statistics__stat {
  color: #e54b21;
}

.statistics__wrap:nth-of-type(n + 4) .statistics__line {
  background-color: #e54b21;
}

.statistics__wrap:nth-of-type(n + 7) > .statistics__stat {
  color: #149188;
}

.statistics__wrap:nth-of-type(n + 7) .statistics__line {
  background-color: #149188;
}

.statistics__wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.statistics__line {
  height: 2px;
  width: 43px;
  margin-bottom: 11.5px;
}

.statistics__title {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 17px;
  margin-bottom: 9px;
}

.statistics__stat {
  font-size: 38px;
  line-height: 34px;
  font-weight: 500;
}

.statistics__symbol {
  opacity: 0;
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.statistics__symbol.show-symbol {
  opacity: 1;
}
