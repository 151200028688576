.socialMediaAccounts {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-inline: -4.5px;
  margin-bottom: 8px;
}

.socialMediaAccounts__iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  border: 1px solid #ffffff;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  border-radius: 100%;
  transition: background-color 0.25s;
  margin-inline: 4.5px;
}

.socialMediaAccounts__iconWrap:hover {
  border: unset;
}

.socialMediaAccounts__iconWrap.facebook:hover {
  background-color: #385084;
}

.socialMediaAccounts__iconWrap.twitter:hover {
  background-color: #369fcd;
}

.socialMediaAccounts__iconWrap.instagram:hover {
  background-color: #e95950;
}

.socialMediaAccounts__iconWrap.youtube:hover {
  background-color: #ff0000;
}

.socialMediaAccounts__iconWrap.linkedin:hover {
  background-color: #0077b5;
}
