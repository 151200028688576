.toggleMode__input {
  width: 0;
  height: 0;
  visibility: hidden;
}

.toggleMode__label {
  display: block;
  width: 78px;
  height: 39px;
  background-color: rgba(000, 000, 000, 0.3);
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
}

.toggleMode__label::after {
  content: "";
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
}

.toggleMode__input:checked + .toggleMode__label::after {
  left: calc(100% - 4px);
  transform: translateX(-100%);
  transform: translate(-100%, -50%);
}

.toggleMode__mode {
  color: #ffffff;
  text-align: center;
  margin-top: 6px;
}
