.zoomControls {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.zoomControls__rangeWrap,
.zoomControls > button {
  width: 39px;
  border-radius: 6px;
  background-color: rgba(000, 000, 000, 0.3);
}

.zoomControls > button {
  height: 39px;
  outline: none;
  border: none;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}

.zoomControls__rangeWrap {
  text-align: center;
  padding-block: 9.5px;
  margin-block: 4px;
  height: 163px;
  position: relative;
}

.zoomControls__range {
  overflow: hidden;
  width: 140px;
  height: 2px;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 50%;
  transform: translateX(-50%) rotate(-90deg);
  outline: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .zoomControls__range {
    -webkit-appearance: none;
  }

  .zoomControls__range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0;
    height: 0;
    box-shadow: -80px 0 0 80px #ffffff;
  }
}

.zoomControls__range::-moz-range-progress {
  background-color: #ffffff;
}

.zoomControls__range::-moz-range-track {
  background-color: #ffffff;
}

.zoomControls__range::-ms-fill-lower {
  background-color: #ffffff;
}
.zoomControls__range::-ms-fill-upper {
  background-color: rgba(255, 255, 255, 0.2);
}
