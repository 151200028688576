.spinner {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  position: absolute;
}

.spinner > img {
  width: 60px;
  height: 60px;
}
