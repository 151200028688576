.showAllButton {
  width: 135px;
  background-color: rgba(000, 000, 000, 0.3);
  border-radius: 3px;
  height: 52px;
  padding-inline: 16px;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  margin-inline: 6px;
  display: flex;
  align-items: center;
  outline: unset;
  border: unset;
  cursor: pointer;
}

.showAllButton > img {
  width: 23.9px;
  height: 23.9px;
  margin-inline-end: 10px;
}
